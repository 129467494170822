import prodConfigFactory, {TelemetryCustomerInstrumentationKey} from "factor-lib/envs/prodConfigFactory";
import IBankinEnvConfig from "./IBankinEnvConfig";

const ProdConfig: IBankinEnvConfig = ({
    ...prodConfigFactory(
        TelemetryCustomerInstrumentationKey,
        // 'https://salmon-grass-0a8343f03.1.azurestaticapps.net'
    ),
    backendUrl: 'https://api.bankin.dimpl.io', // 'https://factor-prod-bankin-function.azurewebsites.net'
    debug: false
});

export default ProdConfig;
