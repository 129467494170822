import React, {useContext} from 'react';
// import reportWebVitals from './reportWebVitals';
import App from "./Pages/Main/App";
import currentEnv from "./env/CurrentEnv";

import GlobalMessageWrapper from "./context/GlobalMessageWrapper";
import LoggerContext from 'factor-lib/services/logger/LoggerContext';
import buildAxios from 'factor-lib/serverUtils/buildAxios';
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Callback from "./Pages/Callback/Callback";
// import NavigatorContext from "factor-lib/components/Navigator/NavigatorContext";
// import RealNavigator from 'factor-lib/components/Navigator/RealNavigator';
import { createRoot } from "react-dom/client";
import AxiosContext from "./context/AxiosContext";
import CurrentEnv from "./env/CurrentEnv";
import { getCustomerToken, storeCustomerToken } from "./customerTokenStorageUtils";
import {FunctionBackendTimeoutMilliseconds} from "factor-lib/serverUtils/axiosConfigUtils";
import {Axios} from "axios";
import ILogger from 'factor-lib/services/logger/ILogger';

import './index.scss';

const customerTokenName = 'customerToken';

const BankinAppWithCustomerTokenWrapper = () => {
    const params = useParams();
    const logger: ILogger = useContext<ILogger | undefined>(LoggerContext)!;
    const customerTokenValue = params[customerTokenName]! /* TODO */
    storeCustomerToken(customerTokenValue);
    return (
        <AxiosContext.Provider value={buildAxios(
            CurrentEnv.backendUrl,
            logger,
            FunctionBackendTimeoutMilliseconds,
            {
                CustomerToken: customerTokenValue
            }
        )}>
            <App displayFullError={CurrentEnv.debug}/>
        </AxiosContext.Provider>
    );
}

const BankinCallbackWithCustomerTokenWrapper = () => {
    const logger: ILogger = useContext<ILogger | undefined>(LoggerContext)!;
    const CustomerToken = getCustomerToken();// from storage
    const axiosInstance: Axios = buildAxios(
        CurrentEnv.backendUrl,
        logger,
        FunctionBackendTimeoutMilliseconds,
        {
            CustomerToken
        });
    return (
        <AxiosContext.Provider value={axiosInstance}>
            <Callback rootUrl={`/${CustomerToken}`}/>
        </AxiosContext.Provider>
    );
};

createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <GlobalMessageWrapper>
            {/*<NavigatorContext.Provider value={RealNavigator.Instance}>*/}
                <LoggerContext.Provider value={currentEnv.logger}>
                    <BrowserRouter>
                        <Routes>
                            <Route path='/callback'
                                   element={<BankinCallbackWithCustomerTokenWrapper />} />
                            <Route path={`/:${customerTokenName}`}
                                   element={<BankinAppWithCustomerTokenWrapper />} />
                        </Routes>
                    </BrowserRouter>
                </LoggerContext.Provider>
            {/*</NavigatorContext.Provider>*/}
        </GlobalMessageWrapper>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
