import { useSearchParams } from "react-router-dom";
import CallbackParsedParameters from "./CallbackParsedParameters";

const Callback = (
    {
        rootUrl
    }: {
        rootUrl: string;
    }
) => {
    const [searchParams] = useSearchParams();

    return (
        <CallbackParsedParameters rootUrl={rootUrl}
                                  itemIdStr={searchParams.get('item_id')}
                                  step={searchParams.get('step')}
                                  success={searchParams.get('success')}/>

    );
};

export default Callback;
