const Mobile = ({}: {}) =>
    <svg width="22px" height="32px" viewBox="0 0 22 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <rect id="Rectangle" x="0.666666667" y="0.666666667" width="20" height="30.6666667" rx="2"/>
            <line x1="20.6666667" y1="26" x2="0.666666667" y2="26"/>
            <line x1="7.33333333" y1="3.33333333" x2="14" y2="3.33333333"/>
            <path d="M10.6666667,28 C10.8507616,28 11,28.1492384 11,28.3333333 C11,28.5174282 10.8507616,28.6666667 10.6666667,28.6666667 C10.4825718,28.6666667 10.3333333,28.5174282 10.3333333,28.3333333 C10.3333333,28.1492384 10.4825718,28 10.6666667,28"/>
        </g>
    </svg>;

export default Mobile;
