import {BankinErrorMessageId, BankinErrorTitleId} from './ErrorConsts';

export interface IBankinErrorProps {
    title: string;
    message: string;
}

const Error = (
    {
        title,
        message
    }: IBankinErrorProps
) =>
    <div className='p-error-message p-padding-6' style={{display: 'flex' /* implicit row */}}>
        <div className='p-no-flex p-error-icon p-margin-top-8'>!</div>
        <div className='p-margin-left-6'>
            <div id={BankinErrorTitleId}
                 className='p-bold' style={{lineHeight: '28px', color: '#F01400' /* New */}}>
                {title}
            </div>
            <div id={BankinErrorMessageId}>{message}</div>
        </div>
    </div>;

export default Error;


