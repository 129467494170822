import BankinStatusCode from "factor-lib/Bankin/BankinStatusCode";
import Button from "factor-lib/Buttons/Button";
import Modal from "factor-lib/Modal";
import Link from "factor-lib/Link";
import {BankinExistingItemsModalId, synchronizationButtonId} from "./ExistingItemsModalConsts";
import IItem from "./model/IItem";

const ExistingItemsModal = (
    {
        existingItems,
        close,
        connectNewItem,
        connectExistingItem,
        connectExistingItemIsLoading
    }: {
        existingItems: IItem[]; // length > 0;
        close: () => void;
        connectNewItem: () => Promise<void>;
        connectExistingItem: (itemId: number) => Promise<void>;
        connectExistingItemIsLoading: boolean
    }
) =>
    <Modal id={BankinExistingItemsModalId}
            maxWidth='600px'
            fullMaxWidth={false}
            close={close}>
        <div className='p-padding-4 p-vertical-center'>
            <div className='p-bold p-margin-bottom-4'>
                Comptes synchronisés
            </div>
            <ul className='p-full-width p-padding-left-5 p-padding-right-5'>
                { existingItems.map((existingItem, index) => (
                    // TODO : remove columns, use level, or even table for alignment
                    <li key={`tr-${index}`} className='columns'>
                        <div className='column'>
                            <div className='p-horizontal-center p-margin-top-5'>
                                <span className='p-no-flex p-error-icon'>!</span>
                                <span className='p-margin-left-6'>{ existingItem.content.bankName }</span>
                            </div>
                        </div>
                        <div className='column'>
                            <div className='p-margin-top-5'>
                                { existingItem.content.status === BankinStatusCode.Ok
                                    ? <span className='p-bold p-horizontal-center p-margin-top-5'>Transactions non détectées</span>
                                    : <Button id={synchronizationButtonId(existingItem.id)}
                                              className='p-vertical-center'
                                              actionO={() => {
                                                  close();
                                                  return connectExistingItem(existingItem.id);
                                              }}
                                              text='Reprendre la connexion'
                                              isLoading={connectExistingItemIsLoading} />
                                }
                            </div>
                        </div>
                    </li>
                ))}

                <li>
                    <div className='p-vertical-center'>
                        <Link id='connectNewItemButtonId'
                              action={{
                                  clickHandler: () => {
                                      close();
                                      return connectNewItem();
                                  }
                              }}
                              text='Connecter un autre compte'/>
                    </div>
                </li>
            </ul>
        </div>
    </Modal>;

  /*
export default class ExistingItemsModal extends TsxComponent<{
    existingItems: IBankinItemDto[]; // length > 0
    connectNewItem: () => Promise<void>;
    connectExistingItem: (itemId: number) => Promise<void>;
    close: () => void;
}> {
    @Prop() protected existingItems!: IBankinItemDto[];
    @Prop() protected connectNewItem!: () => Promise<void>;
    @Prop() protected connectExistingItem!: (itemId: number) => Promise<void>;
    @Prop() protected close!: () => void;

    protected render() {
        return (

        );
    }
}
//*/

export default ExistingItemsModal;
