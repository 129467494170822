import devConfigFactory, {TelemetryCustomerInstrumentationKey} from "factor-lib/envs/devConfigFactory";
import IBankinEnvConfig from "./IBankinEnvConfig";

const DevConfig: IBankinEnvConfig = ({
    ...devConfigFactory(
        TelemetryCustomerInstrumentationKey,
        'https://ashy-meadow-008309903.1.azurestaticapps.net',
    ),
    backendUrl: 'https://factor-dev-bankin-function.azurewebsites.net',
    debug: true
});

export default DevConfig;
