const Synchronize = ({}:{}) =>
    <svg width="42px" height="32px" viewBox="0 0 42 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" transform="translate(1, 0)">
            <polyline points="1.269333333 13.072 6.224 20.4497778 11.9217778 13.6266667"/>
            <polyline points="41.3973333 20.72 36.4462222 13.3386667 30.7448889 20.1635556"/>
            <path d="M36.3804444,13.4044444 C37.6272395,21.306538 32.5872853,28.8385986 24.8071111,30.7004444 C19.7399078,31.9022997 14.4110347,30.4079845 10.70755556,26.7466667"/>
            <path d="M6.23111111,20.4071111 C5.21475043,16.3295034 5.87130791,12.0147767 8.0545349,8.4240395 C10.23776189,4.8333023 13.7663207,2.2647994 17.8542222,1.29066667 C23.2757424,0.00598228566 28.9641519,1.81145775 32.6524444,5.98755556"/>
        </g>
    </svg>;

export default Synchronize;
