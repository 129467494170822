import {useContext, useEffect, useState} from "react";
import StatusLoaded from "./StatusLoaded";
import Loader from "factor-lib/Loader";
import {Axios, AxiosResponse} from "axios";
import AxiosContext from "../../context/AxiosContext";
import slowLoadingMessage from 'factor-lib/serverUtils/slowLoadingMessage';
import IBankinState from "./model/IBankinState";

const Loading = () =>
    <div className='p-vertical-center'>
        <span className='p-margin-bottom-4'> { slowLoadingMessage } </span>
        <Loader />
    </div>;

const App = (
    {
        displayFullError
    }: {
        displayFullError: boolean;
    }
) => {
    const axios: Axios = useContext<Axios | undefined>(AxiosContext)!;
    const [isLoadingStatus, setLoadingStatus] = useState(true);
    const [bankinStatus, setBankinStatus] = useState<IBankinState>(null);
    useEffect(() => {
        axios.get<IBankinState>(
            '/status'
        )
            .finally(() => setLoadingStatus(false))
            .then((r: AxiosResponse<IBankinState>) => setBankinStatus(r.data))
    }, [axios]);

    return (
        <div className='p-padding-3'>
            { isLoadingStatus
                ? <Loading />
                : <div className='container'>
                    <StatusLoaded displayFullError={displayFullError}
                                  bankinStatus={bankinStatus}
                                  setBankinStatus={setBankinStatus} />
                </div>
            }
        </div>
    );
}

export default App;
