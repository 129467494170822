import { useContext, useEffect, useState } from "react";
import GlobalMessageContext from "../../context/GlobalMessageContext";
import Loader from "factor-lib/Loader";
import LoggerContext from "factor-lib/services/logger/LoggerContext";
import {Axios} from "axios";
import AxiosContext from "../../context/AxiosContext";
import errorHandlerMessageHandler from "factor-lib/serverUtils/errorHandlerMessageHandler";
import CurrentEnv from "../../env/CurrentEnv";
import {Navigate} from "react-router-dom";
import ILogger from 'factor-lib/services/logger/ILogger';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import ISynchronizationResult from "./ISynchronizationResult";
import IIncompleteBankinItemErrorParams from "./IIncompleteBankinItemErrorParams";
import {IBankinItemContentDto} from "../../IBankinItemDto";
import IIncompleteBankinPreItemErrorParams from "./IIncompleteBankinPreItemErrorParams";

export const completeItemSuccessUrl = (itemId: number) =>
    `/items/${itemId}/complete-success`;

const completeErrorUrl = `/complete-error`;
export const completeItemErrorUrl = (itemId: number) =>
    `/items/${itemId}${completeErrorUrl}`;

export const OkSuccessValue = 'true';

const callbackAsync = async (
    /* query: Dictionary<string | Array<string | null>>*/
    itemIdStr: string | null,
    success: string | null,
    step: string | null,
    axios: Axios,
    logger: ILogger,
    messageHandler: (message: string) => void,
    displayFullError: boolean
): Promise<void> => {
    // const itemIdStr = query.item_id as string | null;
    const isSuccess: boolean = OkSuccessValue === (success /* query.success as string | undefined */);
    // const step = parseStep(query);
    const p: { [key: string]: any } = {};
    p.itemId = itemIdStr;
    p.success = isSuccess;
    p.step = step;
    logger.trackTrace(SeverityLevel.Information, `Bankin callback : ${window.location.search}`, p);
    await callbackWoMarketingAsync(
        axios,
        messageHandler,
        displayFullError,
        isSuccess,
        itemIdStr,
        step
    );
}

const callbackWoMarketingAsync = async (
    axios: Axios,
    // customerToken: string,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    success: boolean,
    itemIdStr: string | null,
    step: string | null
): Promise<void> => {
    await synchronizationCompletionAsync(
        axios,
        // customerToken,
        messageHandler,
        displayFullError,
        success,
        itemIdStr,
        step,
    );
}

const synchronizationCompletionAsync = (
    axios: Axios,
    // customerToken: string,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    success: boolean,
    itemIdStr: string | null,
    step: string | null
): Promise<void> => {
    if (success) {
        // localhost:1893/item_id=3273161&user_uuid=d6cba86a-a091-49d2-89ec-51f9d8d2b72c&success=true
        // In background
        return onItemSuccessAsync(
            axios,
            // httpClient,
            // customerToken,
            messageHandler,
            displayFullError,
            parseItemId(itemIdStr as string)
        );
    } // else

    if (!!itemIdStr) {
        return onItemErrorAsync(
            axios,
            messageHandler,
            displayFullError,
            parseItemId(itemIdStr as string),
            step
        );
    } // else
    return onPreItemErrorAsync(
        axios,
        messageHandler,
        displayFullError,
        step
    );
}

const parseItemId = (itemIdStr: string): number =>
    parseInt(itemIdStr as string, 10);

const onItemSuccessAsync = async (
    axios: Axios,
    // customerToken: string,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    itemId: number
): Promise<void> => {
    await axios.put<ISynchronizationResult>(
        completeItemSuccessUrl(itemId),
        { }/* ,
        {
            headers: authHeader(customerToken)
        } */
    )
        .catch((e: any) =>
            // httpClient.errorHandler(messageHandler, e)
            errorHandlerMessageHandler(messageHandler, displayFullError, e)
        );
}

const onItemErrorAsync = async (
    axios: Axios,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    itemId: number,
    step: string | null
): Promise<void> => {
    const incompleteError: IIncompleteBankinItemErrorParams = ({
        step
    });
    await axios.put<IBankinItemContentDto>(
        completeItemErrorUrl(itemId),
        incompleteError/* ,
        {
            headers: authHeader(customerToken)
        } */
    )
        .catch((e: Error) =>
            // httpClient.errorHandler(messageHandler, e)
            errorHandlerMessageHandler(messageHandler, displayFullError, e)
        );
}

const onPreItemErrorAsync = async (
    axios: Axios,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    step: string | null,
): Promise<void> => {
    if (!!step) {
        const incompleteError: IIncompleteBankinPreItemErrorParams = ({
            step
        });
        await axios.put(
            completeErrorUrl,
            incompleteError,
            /* {
                headers: authHeader(customerToken)
            } */
        )
            .catch((e: Error) =>
                errorHandlerMessageHandler(messageHandler, displayFullError, e)
            );
    }
}

const CallbackParsedParameters = (
    {
        rootUrl,
        itemIdStr,
        success,
        step
    }: {
        rootUrl: string;
        itemIdStr: string | null;
        success: string | null;
        step: string | null;
    }
) => {
    const [callBackDone, setCallBackDone] = useState<boolean>(false);

    const axios: Axios = useContext<Axios | undefined>(AxiosContext)!;
    const messageHandler: (message: string) => void = useContext<((message: string) => void) | undefined>(GlobalMessageContext)!;
    const logger: ILogger = useContext<ILogger | undefined>(LoggerContext)!;
    const displayFullError = CurrentEnv.debug;

    // called twice locally

    useEffect(() => {
        callbackAsync(
            itemIdStr,
            success,
            step,
            axios,
            logger,
            messageHandler,
            displayFullError
        )
            .finally(() => setCallBackDone(true))
    });

    return (
        callBackDone
            ? <Navigate to={rootUrl}/>
            : <Loader/>

    );
};

export default CallbackParsedParameters;
