import {useContext, useState} from "react";

// import Bankin from 'bankin-lib/Bankin/Bankin';

import BankinStatusCode from 'factor-lib/Bankin/BankinStatusCode';
import IBankinError, {GenericItemError} from 'factor-lib/Bankin/IBankinError';
import GlobalMessageContext from "../../context/GlobalMessageContext";
import nonConnectedBankinItemStatusToMessage from 'factor-lib/Bankin/bankinUtils';
import {Axios, AxiosResponse} from "axios";
import AxiosContext from "../../context/AxiosContext";
import errorHandlerMessageHandler from "factor-lib/serverUtils/errorHandlerMessageHandler";
import { IItemContent } from 'factor-lib/Bankin/bankinUtils';

import Button, {KIND_OUTLINED, SIZE_COMPACT, SIZE_FIXED} from 'factor-lib/Buttons/Button';
import IBankinState from "./model/IBankinState";
import IBankinStarted from "./model/IBankinStarted";
import Error from "./Error";
import CardPresentation from "./CardPresentation";
import {SynchronizeBankinId} from "./Consts";
import ExistingItemsModal from "./ExistingItemsModal";

const refreshingUrl = `/refresh`;
const newItemConnectUrl = `/connect`;
const existingConnectUrl = (itemId: number) => `/connect/${itemId}`;

interface IItem {
    id: number;
    content: IItemContent;
}

const computeSingleBankinError = (bankinStatus: IBankinState): IBankinError | null => {
    if (!bankinStatus) {
        return null;
    }

    // const bankinItems = bankinStatus.items;

    const bankinItemsNonConnected = bankinStatus.items
        .filter((bi) => bi.content.status !== BankinStatusCode.Ok);

    // if (bankinStatus.connected) {
    //     return null;
    // }

    if (!bankinItemsNonConnected || bankinItemsNonConnected.length === 0) {
        return null;
    }

    if (bankinItemsNonConnected.length === 1) {
        const bankinItemStatus = nonConnectedBankinItemStatusToMessage.get(bankinItemsNonConnected[0].content.status);
        if (!!bankinItemStatus) {
            return bankinItemStatus;
        }
    }

    /*
    if (BankItems.filter((bi) => bi.Content.Status === BankinStatusCode.Ok).length > 0) {
        return NoTransactionsError;
    }
    */

    return GenericItemError;
};

const refreshingAsync = async (
    axios: Axios,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    setBankinStatus: (newBankItems: IItem[]) => void,
): Promise<void> => {
    await axios.put<IItem[]>(
        refreshingUrl
    )
        .then((response2: AxiosResponse<IItem[]>) => {
            const bankItems: IItem[] = response2.data;
            setBankinStatus(bankItems);
        })
        .catch((e: any) =>
            errorHandlerMessageHandler(messageHandler, displayFullError, e)
        );
}

const connectNewItemAsync = async (
    setConnectNewItemIsLoading: (newConnectNewItemIsLoading: boolean) => void,
    axios: Axios,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    // navigator: INavigator,
    bankinStatus: IBankinState,
    setBankinStatus: (newBankinStarted: IBankinStarted) => void,
): Promise<void> => {
    setConnectNewItemIsLoading(true);
    await axios.get<string>(
        newItemConnectUrl
        /* {
            headers: authHeader(customerToken)
        } */
    )
        .finally(() => setConnectNewItemIsLoading(false))
        .then((response: AxiosResponse<string>) => {
            setConnectNewItemIsLoading(true);
            if (!bankinStatus) {
                // Bankin user has been created
                setBankinStatus({
                    items: []
                });
            }
            openBankinConnectUrl(
                // navigator,
                response.data
            );
        })
        .catch((e: Error) =>
            errorHandlerMessageHandler(messageHandler, displayFullError, e)
        );
}

const openBankinConnectUrl = (
    // navigator: INavigator,
    url: string
): void => {
    // TODO : reset all the time :'(
    // navigator.goTo(url);
    window.location.assign(url);
}

const clickAsync = async (
    axios: Axios,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    bankinState: IBankinState,
    setBankinStatus: (newBankinItems: IItem[]) => void,
    setDisplayExistingItemsModal: (newDisplayExistingItemsModal: IItem[]) => void,
    connectNewItemAsync: () => Promise<void>
): Promise<void> => {
    if (!!bankinState) {
        await refreshingAsync(axios, messageHandler, displayFullError, setBankinStatus);
    }

    // Reload the bankin items
    const bankItems = bankinState?.items;
    if (!!bankItems &&
        bankItems.filter((bi: IItem) => bi.content.status !== BankinStatusCode.Ok).length > 0
    ) {
        setDisplayExistingItemsModal(bankItems)
    } else {
        await connectNewItemAsync();
    }
}

const connectExistingItemAsync = async (
    axios: Axios,
    // customerToken: string,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    // navigator: INavigator,
    setConnectExistingItemIsLoading: (newConnectExistingItemIsLoading: boolean) => void,
    itemId: number
) => {
    setConnectExistingItemIsLoading(true);
    await axios.get<string>(
        existingConnectUrl(itemId),
    )
        .finally(() => setConnectExistingItemIsLoading(false))
        .then((response: AxiosResponse<string>) =>
            openBankinConnectUrl(
                // navigator,
                response.data
            )
        )
        .catch((e: Error) =>
            // httpClient.errorHandler(messageHandler, e)
            errorHandlerMessageHandler(messageHandler, displayFullError, e)
        );
}

const synchronizeNewAccount = (
    axios: Axios,
    messageHandler: (message: string) => void,
    displayFullError: boolean,
    // navigator: INavigator,
    setDisplayExistingItemsModal: (newDisplayExistingItemsModal: IItem[]) => void,
    setConnectNewItemIsLoading: (newConnectNewItemIsLoading: boolean) => void,
    bankinState: IBankinState,
    setBankinStatus: (newBankinStarted: IBankinStarted) => void,
    setSynchronizing: (newValue: boolean) => void
): void => {
    setSynchronizing(true);
    clickAsync(
        axios,
        messageHandler,
        displayFullError,
        bankinState,
        (items: IItem[]) => setBankinStatus({
            items
        }),
        setDisplayExistingItemsModal,
        () => connectNewItemAsync(
            setConnectNewItemIsLoading,
            axios,
            messageHandler,
            displayFullError,
            // navigator,
            bankinState,
            setBankinStatus
        )
    )
        .finally(() => setSynchronizing(false))
}

const BankinIncompleteNonLoading = (
    {
        bankinStatus
    }: {
        bankinStatus: IBankinState;
    }
) => {
    const singleBankinError = computeSingleBankinError(bankinStatus);
    return (
        /* preItemError
                            ? <Error title={GenericItemError.title}
                                        message={GenericItemError.message} />
                            : */ !!singleBankinError
            ? <Error title={singleBankinError.title}
                     message={singleBankinError.message} />
            : <CardPresentation className={null} />
    );
}

const StatusLoaded = (
    {
        displayFullError,
        // customerToken,
        bankinStatus,
        setBankinStatus,
    }: {
        displayFullError: boolean;
        // customerToken: string,
        bankinStatus: IBankinState;
        setBankinStatus: (newBankinStarted: IBankinStarted) => void;
    }
) => {
    const [connectNewItemIsLoading, setConnectNewItemIsLoading] = useState<boolean>(false);
    const [connectExistingItemIsLoading, setConnectExistingItemIsLoading] = useState<boolean>(false);
    const [displayExistingItemsModal, setDisplayExistingItemsModal] = useState<IItem[] | null>(null);
    const [synchronizing, setSynchronizing] = useState(false);
    const axios: Axios = useContext<Axios | undefined>(AxiosContext)!;
    // const navigator: INavigator = useContext<INavigator | undefined>(NavigatorContext)!;
    const messageHandler: (message: string) => void = useContext<((message: string) => void) | undefined>(GlobalMessageContext)!;
    const connectedItemsWithTransactions: IItem[] | null = !!bankinStatus
        ? bankinStatus.items.filter((bi) => bi.content.status === BankinStatusCode.Ok && bi.content.hasAnyTransaction)
        : null;
    // const completed: boolean = !!bankinStatus?.connected;

    return (
        <div>
            { !connectedItemsWithTransactions || connectedItemsWithTransactions.length === 0
                ? <div>
                    { synchronizing || connectNewItemIsLoading
                        ? <div className='p-ok-message p-padding-6'
                            style={{display: 'flex' /* implicit row */}}>
                            <div className='p-no-flex p-icon p-ok-border p-ok-text p-margin-top-8'>!</div>
                            <div className='p-margin-left-6'>
                                <div className='p-bold' style={{lineHeight: '28px'}}>
                                    Un peu de patience:
                                </div>
                                <div>Votre compte bancaire est en cours de traitement..</div>
                            </div>
                        </div> : <BankinIncompleteNonLoading bankinStatus={bankinStatus} />
                     }
                    <div className='p-right p-margin-top-4'>
                        <Button id={SynchronizeBankinId}
                                kind={KIND_OUTLINED}
                                size={SIZE_COMPACT}
                                text='Synchroniser'
                                actionO={(!synchronizing && (() => synchronizeNewAccount(
                                    axios,
                                    messageHandler,
                                    displayFullError,
                                    // navigator,
                                    (newDisplayExistingItemsModal: IItem[]) => setDisplayExistingItemsModal(newDisplayExistingItemsModal),
                                    setConnectNewItemIsLoading,
                                    bankinStatus,
                                    setBankinStatus,
                                    setSynchronizing
                                ))) || null /* (!completed && !synchronizing) ? click : null */}
                                isLoading={connectNewItemIsLoading /* clickIsLoading */} />
                    </div>
                </div> : <div>
                    <div>Les relevés bancaires suivants ont été synchronisés avec succès.</div>
                    <ul style={{listStyle: "inside"}}
                        className='p-margin-top-5 p-margin-bottom-5'>
                        { connectedItemsWithTransactions.map(i => <li> {i.content.bankName}</li> ) }
                    </ul>

                    <span>
                        <div>Vous pouvez à présent fermer cet onglet ou en connecter de nouveaux.</div>
                        <div> Merci de la part de l’équipe Dimpl. </div>
                    </span>
                    <div className='p-left p-margin-top-4'>
                        <Button id={SynchronizeBankinId}
                                kind={KIND_OUTLINED}
                                size={SIZE_FIXED}
                                text='Synchroniser un nouveau compte'
                                actionO={(!synchronizing && (() => synchronizeNewAccount(
                                    axios,
                                    messageHandler,
                                    displayFullError,
                                    // navigator,
                                    (newDisplayExistingItemsModal: IItem[]) => setDisplayExistingItemsModal(newDisplayExistingItemsModal),
                                    setConnectNewItemIsLoading,
                                    bankinStatus,
                                    setBankinStatus,
                                    setSynchronizing
                                ))) || null /* (!completed && !synchronizing) ? click : null */}
                                isLoading={connectNewItemIsLoading /* clickIsLoading */} />
                    </div>
                </div>
            }

            { displayExistingItemsModal &&
                <ExistingItemsModal existingItems={displayExistingItemsModal}
                                    close={() => setDisplayExistingItemsModal(null)}
                                    connectNewItem={() => connectNewItemAsync(
                                              setConnectNewItemIsLoading,
                                              axios,
                                              messageHandler,
                                              displayFullError,
                                              // navigator,
                                              bankinStatus,
                                              setBankinStatus
                                          )}
                                    connectExistingItem={(itemId: number) => connectExistingItemAsync(
                                              axios,
                                              messageHandler,
                                              displayFullError,
                                              // navigator,
                                              setConnectExistingItemIsLoading,
                                              itemId
                                          )}
                                    connectExistingItemIsLoading={connectExistingItemIsLoading}/>
            }
        </div>
    );
}

export default StatusLoaded;
