import {ReactNode, useState} from "react";
import TextOnlyModal from 'factor-lib/Modals/TextOnlyModal';
import GlobalMessageContext from "./GlobalMessageContext";

const GlobalMessageWrapper = ({ children }: {children: ReactNode}) => {
    const [globalMessage, setGlobalMessage] = useState<string | null>(null);
    return (
        <div>
            { !!globalMessage &&
                <TextOnlyModal fullMaxWidth={false}
                               maxWidth={null}
                               message={globalMessage}
                               close={() => setGlobalMessage(null)}
                               buttonsProps={{
                                   id: 'errorButtonId',
                                   actionO: () => setGlobalMessage(null),
                                   isLoading: false,
                                   text: 'Ok'
                               }}/>

            }
            <GlobalMessageContext.Provider value={setGlobalMessage}>
                { children }
            </GlobalMessageContext.Provider>
        </div>
    );
}

export default GlobalMessageWrapper;
