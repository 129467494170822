const Document = ({}: {}) =>
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <path d="M15.3333333,2 L30,2 C30.7363797,2 31.3333333,2.59695367 31.3333333,3.33333333 L31.3333333,30 C31.3333333,30.7363797 30.7363797,31.3333333 30,31.3333333 L2,31.3333333 C1.26362033,31.3333333 0.666666667,30.7363797 0.666666667,30 L0.666666667,3.33333333 C0.666666667,2.59695367 1.26362033,2 2,2 L6,2"/>
            <path d="M15.3333333,14 C15.3333333,14.7333333 14.9333333,14.8813333 14.4546667,14.3306667 L10.6666667,10 L6.87466667,14.328 C6.39066667,14.88 5.996,14.728 5.996,13.9986667 L5.996,1.99866667 C5.996,1.262287 6.59295367,0.665333333 7.32933333,0.665333333 L13.996,0.665333333 C14.7323797,0.665333333 15.3293333,1.262287 15.3293333,1.99866667 L15.3333333,14 Z"/>
            <line x1="19.3333333" y1="8.66533333" x2="27.3333333" y2="8.66533333"/>
            <line x1="19.3333333" y1="13.9986667" x2="27.3333333" y2="13.9986667"/>
            <line x1="4.66666667" y1="19.332" x2="27.3333333" y2="19.332"/>
            <line x1="4.66666667" y1="24.6653333" x2="26.6626667" y2="24.6653333"/>
        </g>
    </svg>;

export default Document;
