import LocalConfig from "factor-lib/envs/LocalConfig";
import DevConfig from "./DevConfig";
import DemoConfig from "./DemoConfig";
import ProdConfig from "./ProdConfig";
import {getEnv, ensureNonNullEnvName, envError} from 'factor-lib/utils/envUtils';
import IBankinEnvConfig from "./IBankinEnvConfig";
import IBankinEnv from "./IBankinEnv";

const currentConfigO: IBankinEnvConfig | null =
    getEnv<IBankinEnvConfig | null>(
        () => ensureNonNullEnvName(process.env.REACT_APP_ENV_NAME),
        {
            ...LocalConfig,
            backendUrl: 'http://localhost:7071',
            debug: true
        },
        DevConfig,
        DemoConfig,
        ProdConfig
    );

const CurrentConfigEnv: IBankinEnvConfig = !!currentConfigO ? currentConfigO : envError();

const CurrentEnv: IBankinEnv = ({
    ...CurrentConfigEnv,
    cookiesManager: CurrentConfigEnv.cookiesManager(),
    logger: CurrentConfigEnv.logger()
});

export default CurrentEnv;
